import React, { useState } from "react";

const LoginForm = ({ onLogin }) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const staticPassword = "tcm434@!@#"; // Replace with your static password

  const handleLogin = (e) => {
    e.preventDefault();
    if (password === staticPassword) {
      // Save login timestamp in localStorage
      const loginExpiration = Date.now() + 30 * 60 * 1000; // 30 minutes in milliseconds
      localStorage.setItem("isLoggedIn", "true");
      localStorage.setItem("loginExpiration", loginExpiration);

      onLogin(); // Call the function to update the login state
    } else {
      setError("Invalid password. Please try again.");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <form
        onSubmit={handleLogin}
        className="w-96 p-6 bg-white rounded-2xl shadow-md space-y-6"
      >
        <h1 className="text-2xl font-bold text-center">Coming Soon</h1>
        <h2 className="text-2xl font-bold text-center">Login</h2>
        <div className="space-y-4">
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 mt-1 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your password"
              required
            />
          </div>
          {error && <p className="text-sm text-red-500">{error}</p>}
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 text-white bg-blue-600 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none"
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
